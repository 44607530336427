import React from 'react';
import { FaAt, FaTwitter, FaLinkedin } from 'react-icons/fa';

const Contacts = () => (
  <div id="contactBar">
    <br />
    <h3>Contact me</h3>
    <p>
      <a href="mailto:web@mjwasbrough.com">
        <FaAt className="contactIcon" />
      </a>
      &nbsp;
      <a href="https://twitter.com/MJWasbrough">
        <FaTwitter className="contactIcon" />
      </a>
      &nbsp;
      <a href="https://uk.linkedin.com/pub/matthew-wasbrough/45/3a/674">
        <FaLinkedin className="contactIcon" />
      </a>
    </p>
  </div>
);

export default Contacts;
