import React from "react";
import { Link } from "@reach/router";

const Python = () => (
  <div id="content">
    <h3>Python Course - Learn Python Programming, MongoDB, Django...</h3>
    <p>
      Given my background in science and with limitted knowledge of Python this
      was my start point of relearning programming. This course was through{" "}
      <a href="https://www.udemy.com/learn-programming-quick-with-python-by-examples/">
        Udemy
      </a>{" "}
      but sumplimented by study through{" "}
      <a href="https://www.codecademy.com/">Codecademy</a>.
    </p>
    <p>
      While it was nice to get back into programming this course was very much
      at the beginners level and therefore I knew a lot of the information
      already. However it did provide some extra knoledge that I had missed and
      gave very brief introductions in Django and MongoDB. This list of modules
      covered is as follows:
    </p>
    <ol>
      <li>Basics of Python Programming Language</li>
      <li>Object Oriented Programming, Inheritance, Garbage Collection</li>
      <li>File Processing</li>
      <li>Python with SQLite</li>
      <li>Python for XML Related Tasks</li>
      <li>HTML, CSS</li>
      <li>Django</li>
      <li>Cloud Computing & MongoDB</li>
      <li>Tkinter</li>
      <li>
        How To Use Python For Other Technologies, such as PIL and wxPython
      </li>
    </ol>
    <p>
      A full description of the course can be found at{" "}
      <a href="https://www.udemy.com/learn-programming-quick-with-python-by-examples/">
        Udemy
      </a>{" "}
      and my certificate of completion can be seen{" "}
      <Link to="/programming/python/pythoncertificate">here</Link>.
    </p>
    <p>
      <Link to="/programming/courses">Back to Courses</Link>
    </p>
  </div>
);

export default Python;
