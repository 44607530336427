import React from "react";
import { Link } from "@reach/router";

const Programming = props => (
  <div id="content">
    <h1>Programming</h1>
    <h3>Current Interests</h3>
    <p>
      My main area of interest is in JavaScript, although I like to keep my had
      in with other programming languages such as C#/.NET, Java, and Python. I
      don't consider myself as either a front or back end developer explicitly.
      The only technology I have so far aligned myself with is React. An area
      that I have been particularly interested in for a long time but have found
      it difficult to develop is in testing. Recently this has taken more
      importance in my career and I have been working with Jest to accomplish
      this.
    </p>
    <p>
      To get a better idea of my current knowledge checkout out my{" "}
      <Link to="/programming/jobs">jobs</Link> page to see what I have worked
      with.
    </p>
    <h3>Latest Open Things</h3>
    <ul id="latestList">
      <li>
        <a href="https://medium.com/@matthew.was/testing-backend-services-with-jest-5889db54b56a">
          Testing backend services with Jest - 30/08/2018
        </a>
      </li>
    </ul>
    <h3>Jobs</h3>
    <p>
      Below are the jobs I have held as a developer. For more information about
      what I did in each position please see my more detailed{" "}
      <Link to="/programming/jobs">jobs</Link> page.
    </p>
    <p className="jobTitle">Logic Room</p>
    <p className="jobDates">November 2018 - Current</p>
    <p className="jobTitle">M3 (EU) Ltd</p>
    <p className="jobDates">May 2016 - November 2018</p>
    <h3>Self Directed Learning</h3>
    <p>
      With the rapidly chaning pace of technology, particularly in JavaScript I
      attempt to stay abreast. Mostly the learning is done by reading Medium
      posts, watching conference videos, or following other developers on
      Twitter. Where my learning has been more directed, through specific
      courses, I have detailed them <Link to="/programming/courses">here</Link>.
    </p>
  </div>
);

export default Programming;
