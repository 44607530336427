import React from "react";
import ReactDOM from "react-dom";
import { Router, Link } from "@reach/router";

import NavBar from "./navbar";
import Contacts from "./contacts";
import About from "./about";
import Programming from "./programming";
import Courses from "./programming/Courses";
import Python from "./programming/Courses/python";
import Certficate from "./programming/Courses/python/certificate";
import CompWebDev from "./programming/Courses/compWebDev";
import Jobs from "./programming/Jobs";
import Science from "./science";

import "./styles.css";

const App = props => {
  return (
    <Router>
      <Content path="/">
        <Home path="/" />
        <About path="/about" />
        <Programming path="/programming" />
        <Courses path="/programming/courses" />
        <Python path="/programming/python" />
        <Certficate path="/programming/python/pythoncertificate" />
        <CompWebDev path="/programming/completewebdeveloper" />
        <Jobs path="/programming/jobs" />
        <Science path="/science" />
      </Content>
    </Router>
  );
};

const Content = props => (
  <div className="App">
    <NavBar />
    {props.children}
    {props.location.pathname === "/" ? null : <Contacts />}
  </div>
);

const Home = () => (
  <div id="mainTitle">
    <h1>Matthew Wasbrough</h1>
    <h2>
      <Link to="/programming" className="homeLink">
        <span>Web Developer</span>
      </Link>{" "}
      and{" "}
      <Link to="/science" className="homeLink">
        <span>Scientist</span>
      </Link>
    </h2>
  </div>
);

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
