import React from "react";
import { Link } from "@reach/router";

const About = () => (
  <div id="content">
    <h1>About</h1>
    <p>
      I am a web developer based in West Berkshire. Having been a research
      scientist previously I am continuously learning my trade and figuring out
      what part of the stack I enjoy working in the most.
    </p>
    <p>
      My most recent role was primarily focused on JavaScript/NodeJS however to
      support other business needs I spent time working with Java and C#/.NET. I
      built my skills around the programming work of a developer with experience
      in Postgres, Mongo and Redis databases. I have also built up knowledge of
      the wider development environment working with Docker for
      containerisation, CircleCI and Hubot for a CI/CD, AWS for hosting, and Git
      (github, gitlab and gogs) for code version control. This role has been
      performed under agile principles with major projects run as Scrum and
      longer term projects under Kanban.
    </p>
    <p>
      To become a web developer I taught myself the basic principles of HTML,
      CSS and client side JavaScript with the help of online tutorials. A
      summary of the courses I took is <Link to="/programming">here</Link>.
      While this was a big change in career I have benefitted from an enthusiasm
      to learn and the self belief that any coding problem is solvable with
      enough perseverance. These skills have transfered very effectively from my
      time as a scientist.
    </p>
    <p>
      Prior to becoming a web developer I earned a Doctorate and an
      undergraduate Masters in Chemistry, both from the University of Bath.
      Following the completion of my Doctorate I worked at the National
      Institute of Standards and Technology, Center for Neutron Research as part
      of the Macromolecular and Microstructural Sciences Group. It remains my
      hope that I will be able to bring my scientific knowledge into my web
      development work however I have not done this so far. Further information
      is available on my <Link to="/science">science</Link> page.
    </p>
    <p>
      Although my experience level is low I would be interested in disucssing
      potential jobs so please feel free to contact me using the methods below.
    </p>
  </div>
);

export default About;
