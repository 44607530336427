import React from "react";

class Science extends React.Component {
  constructor(props) {
    super(props);
    this.state = { abstractView: false };
  }

  toggleAbstract = () => {
    this.setState(prevState => ({
      ...prevState,
      abstractView: !this.state.abstractView
    }));
  };

  render() {
    return (
      <div id="content">
        <h1>My Science</h1>
        <p>
          I completed my doctorate in Chemistry in 2011 at the University of
          Bath under{" "}
          <a href="http://www.bath.ac.uk/chemistry/contacts/academics/karen_edler/">
            Dr. Karen Edler
          </a>
          . Prior to this I was awarded a MChem in Chemistry with North American
          Study from the University of Bath, although this was started at the
          University of Exeter. My North American study year was completed at
          the University of Southern Mississippi.
        </p>
        <p>
          My doctoral thesis was titled{" "}
          <span style={{ fontWeight: "bold" }}>
            "Nanostructured hydrogel films for encapsulation and release"
          </span>{" "}
          and is freely availale on the{" "}
          <a href="http://opus.bath.ac.uk/27931/">
            University of Bath Website.
          </a>
        </p>
        <p>
          <button id="abstractButton" onClick={() => this.toggleAbstract()}>
            Click here to{" "}
            <span style={{ fontWeight: "bold" }}>
              {this.state.abstractView ? "hide" : "view"}
            </span>{" "}
            the abstract
          </button>
        </p>

        <div
          id="abstract"
          style={
            this.state.abstractView
              ? { display: "inherit" }
              : { display: "none" }
          }
        >
          <p>
            We have been investigating solutions of surfactants and
            polyelectrolytes that are able to spontaneously form micron thick
            hydrogel films at the air/solution interface. Previous research
            within the group has shown that the surfactant can act as a
            templating agent for the polymer hydrogel, leading to well ordered
            mesostructured films similar to those seen in surfactant templated
            inorganic materials or polymeric layer-by-layer films, which have
            both been highly active areas of research over the past 20 years.
            This project built on the previous research within the group to
            develop a greater understanding of these films and how they may be
            controlled and used for real world applications.
          </p>
          <p>
            This thesis concentrates on two areas; the first is films made from
            a cationic surfactant/polymer system and the second from a
            cat-anionic surfactant/polymer system. Using the cationic
            surfactant/polymer system, we built on the previous research by
            altering the head group area to tail volume ratio of the surfactants
            being used to allow control over the final mesostructure within the
            films. Small angle neutron scattering (SANS) was used to study the
            bulk solution while neutron and x-ray reflectivity and grazing
            incidence diffraction were used to study the films. Using this
            system we have also developed the previous work, studying the
            incorporation of sparingly soluble species in films, by studying the
            encapsulation of hydrophobic and amphiphilic species into the
            surfactant micelles and therefore into the films as a function of
            micelle and film structure. We have also studied, using Fluorescence
            spectroscopy, the subsequent release of these species from the
            films.
          </p>
          <p>
            The cat-anionic surfactant/polymer system was discovered more
            recently and therefore has not had as much previous research
            performed on it. It is of particular interest because it allows
            films to be formed from a variety of different polymers where the
            cationic system currently has only been found to form films with one
            polymer. Using this cat-anionic system we have studied the
            surfactant interactions in the bulk with SANS, ultra-SANS and
            spin-echo-SANS to determine the structure over a wide length range.
            This data is compared to cryo-SEM results. In particular cryo-SEM
            and USANS have been used to validate modelling from the new
            technique of SESANS. We then studied the film formation with x-ray
            and neutron reflectivity. As well as forming from a wider range of
            polymers, these films are much thicker and more robust, which may be
            due to the larger scale aggregates formed in solution.
          </p>
        </div>

        <p>
          Here are links to my online science presence. If you have any
          questions about any of my work please feel free to contact me using
          the links at the bottom.
        </p>

        <h3>Websites</h3>

        <p>
          <a href="https://scholar.google.co.uk/citations?hl=en&user=EES_xVoAAAAJ">
            Google Scholar
          </a>
        </p>
        <p>
          <a href="http://www.researcherid.com/rid/H-2252-2013" id="ri">
            ResearcherID
          </a>
        </p>
        <p>
          <a href="https://orcid.org">
            <img
              alt="ORCID logo"
              src="https://orcid.org/sites/default/files/images/orcid_16x16.png"
              width="16"
              height="16"
            />
          </a>{" "}
          <a
            href="https://orcid.org/0000-0002-3225-7447"
            style={{ verticalAlign: "top" }}
          >
            https://orcid.org/0000-0002-3225-7447
          </a>
        </p>
      </div>
    );
  }
}

export default Science;
