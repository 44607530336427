import React from "react";
import { Link } from "@reach/router";

const Certificate = () => (
  <div id="content">
    <br />
    <img
      src="https://res.cloudinary.com/mjwas/image/upload/f_auto,q_auto:good/v1542109856/PythonCertificate.jpg"
      alt="Udemy Python Course Certificate"
      width="80%"
      style={{ border: "5px solid darkgrey" }}
    />
    <br />
    <p>
      <Link to="/programming/python">Back</Link>
    </p>
  </div>
);

export default Certificate;
