import React from "react";
import { Link } from "@reach/router";

const Links = () => (
  <div id="nav-links">
    <ul>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/programming">Programming</Link>
      </li>
      <li>
        <Link to="/science">Science</Link>
      </li>
    </ul>
  </div>
);

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuView: false };
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      ...prevState,
      menuView: !this.state.menuView
    }));
  };

  render() {
    return (
      <>
        <div id="navbar">
          <div id="nav-brand">
            <Link to="/">MJWasbrough.com</Link>
          </div>
          <div id="nav-links-w">
            <Links />
          </div>
          <div id="nav-links-n">
            <button id="nav_menu-toggle" onClick={() => this.toggleMenu()} />
            {this.state.menuView && <Links />}
          </div>
        </div>
        <div className="clearBorder" />
      </>
    );
  }
}

export default NavBar;
