import React from "react";
import { Link } from "@reach/router";

const Jobs = () => (
  <div id="content">
    <h1>Jobs</h1>
    <h3>Logic Room</h3>
    <p>November 2018 - Current</p>
    <h3>M3 (EU) Ltd</h3>
    <p>May 2016 - November 2018</p>
    <p>
      My first position as a developer was with{" "}
      <a href="https://eu.m3.com/">M3 (EU) Ltd.</a> as a junior developer (May
      2016 - October 2017) and then as a developer (October 2017 - November
      2018). As part of a small team we worked to maintain, redevelop and add
      new features to <a href="https://www.doctors.net.uk">Doctors.net.uk</a>{" "}
      and <a href="https://www.m3medical.com">m3medical.com</a>.
    </p>
    <p>
      Most of the frsh development and redevelopment are Javascript
      microservices which are deployed to AWS servers as docker containers. I
      have been involved in the developemnt of both cronteoller layer and
      service layer Javascript. The controller layer using React as a front end
      and the service layer connecting to Mongo and Postgres databases.
    </p>
    <p>
      The maintainance of the existing services is in many different systems. In
      the newer architecture I have worked on dockerised Java services
      connecting to Postgres databases. I have also worked on the legacy system
      in .NET (C#), connecting to a MS-SQL database.
    </p>
    <p>
      Other systems I have used, not directly related to development, are Github
      and other Git services for version control and team collaboration, where
      we make use of the pull request features to allow code review. The Git
      services are hooked into Continuous Integrations systems for building
      deployment ready applications. The team works in an Agile environment with
      a Kanban style backlog for non project items and bugs and Scrum style
      sprints for project related development. While I haven't been directly
      involved in infrastructure tasks I have completed small projects that have
      exposed me in passing to the AWS platform, deploying EC2 instances and AWS
      lambdas.
    </p>
    <h3>Transition from Scientist to Programmer</h3>
    <p>
      After finishing my post-doctoral work I decided to concentrate more on
      programming. During my time as a scientist my programming knowledge had
      been very bitty and fragmented. This meant to focus on programming as a
      carrer I had to go back to basics to check I did not miss anything. A list
      of courses that I completed, started (or still hope to complete in the
      future) is <Link to="/programming/courses">here</Link>. This is not an
      exhaustive list so please contact me using the links below if you require
      more information.
    </p>
    <h3>Early Career</h3>
    <p>
      My initial knowledge of programming was in HTML and CSS in the mid '90s.
      At the time programming as a career was never made obvious to me otherwise
      things might be very different today. During my time at University as an
      undergraduate I became aware of more programming languages and was first
      introduced to Fortran 77 and Fortran 95. It was during my doctorate that
      my interest in programming was rekindled with basic knowledge of{" "}
      <a href="http://wavemetrics.com/">IgorPro</a>,{" "}
      <a href="https://certif.com/spec.html">SPEC</a> and{" "}
      <a href="https://www.python.org/">Python</a>.
    </p>
    <p>
      During my postdoctoral work, still based in science, I learnt more about
      Bash and Python, in the form of{" "}
      <a href="http://www.sasview.org/">SASView</a> and was introduced to Java
      and C, although only in passing including discussions on adapting the
      fitting routines in SASView to C and CUDA to allow data fitting using
      GPGPU. For more information about this project contact{" "}
      <a href="http://pkienzle.github.io/">Paul Kienzle</a>.
    </p>
  </div>
);

export default Jobs;
