import React from "react";
import { Link } from "@reach/router";

const Courses = () => (
  <div id="content">
    <h1>Courses</h1>
    <h3>As a developer</h3>
    <p>
      Since being a developer I have a much greater understanding of wha
      knowledge is important to me and my future career. As such I have taken
      courses that are much more specific to a single topic.
    </p>
    <ul>
      <li>
        <a href="https://testingjavascript.com/">
          TestingJavaScript.com - Kent C. Dodds
        </a>
      </li>
      <li>
        <a href="https://github.com/getify/You-Dont-Know-JS">
          You Don't Know JS - Kyle Simpson
        </a>
      </li>
    </ul>
    <p>
      Previously though my job I had access to{" "}
      <a href="https://app.pluralsight.com">PluralSight</a> where I took a range
      of courses about Git, SQL, PostgreSQL, Data Warehousing, and Testing.
    </p>
    <p>
      I have a personal subscription to{" "}
      <a href="https://egghead.io">Egghead.io</a> and have been working through
      courses mainly around React, Redux, Typescript, and Jest.
    </p>
    <h3>Learning to be a devloper</h3>
    <p>
      While transitioning from being a scientist to being a programmer these are
      the courses I took. I credit a lot of my success since to the foundations
      that some of these courses gave me. Due to finding a job I have not gone
      back to complete these courses as I have focused on learning skills more
      directly related to my position.
    </p>
    <ul>
      <li>
        <Link to="/programming/python">
          Python Course - Learn Python Programming, MongoDB, Django...
        </Link>
        <br />
        <span className="indentList">- Completed.</span>
      </li>
      <li>
        <Link to="/programming/completewebdeveloper">
          The Complete Web Developer Course
        </Link>
        <br />
        <span className="indentList">- 60% Complete.</span>
      </li>
      <li>
        <a href="https://www.udemy.com/understand-javascript/">
          Javascript: Understanding the weird parts
        </a>
        <br />
        <span className="indentList">- Yet to be done.</span>
      </li>
    </ul>
  </div>
);

export default Courses;
