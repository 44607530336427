import React from "react";
import { Link } from "@reach/router";

const CompWebDev = () => (
  <div id="content">
    <h2>The Complete Web Developer Course</h2>
    <p>
      The first six sections were completed under the first version of{" "}
      <a href="https://www.udemy.com/complete-web-developer-course/">
        The Complete Web Developer Course
      </a>
      . This was superseded by{" "}
      <a href="https://www.udemy.com/the-complete-web-developer-course-2/">
        The Complete Web Developer Course 2
      </a>
      . As of March 16 2016 I transfered onto version 2 of the course to learn
      PHP and onwards, including the final three sections showsn below which
      were not available in version 1. I will go back and double check the first
      6 sections to check if I have missed anything
    </p>
    <p>
      As with the Python course this course was complimented by my previous
      knowledge and work I had done on{" "}
      <a href="https://www.codecademy.com/">codecademy.com</a>.
    </p>
    <p>
      This course started slowly but quickly ramped up into areas I had only
      passing knowledge about, namely some parts of jQuery, AJAX, regex in a
      website, Twitter Bootstrap and Wordpress. I am still working my way
      through this course studying PHP with the further modules detailed below
      to be completed in due course. Each completed item contains a link to the
      end of section project. The HTML, CSS, Javascript and jQuery Project are
      very similar to the course projects, although usually completed in advance
      of watching the video. The Bootstrap was based on the course but of my own
      design. The Wordpress site is entirely mine and has been developed for a
      client (as of March 16th 2016 this site is still under development). As
      second bootstrap page is in production for a second client who also
      requires eCommerce.
    </p>
    <ol>
      <li>
        <a href="/udemyProjects/html.html">HTML</a>
      </li>
      <li>
        <a href="/udemyProjects/bbc.html">CSS</a> (a copy of this is available
        as a{" "}
        <a href="/udemyProjects/bbcImages/bbcArchiveScreenshot.png">
          screenshot
        </a>
        )
      </li>
      <li>
        <a href="/udemyProjects/javascript.html">Javascript</a>
      </li>
      <li>
        <a href="/udemyProjects/jquery.html">jQuery</a>
      </li>
      <li>
        <a href="/udemyProjects/bootstrap.html">Twitter Bootstrap</a>
      </li>
      <li>
        <a href="http://theshireschiropractic.co.uk">Wordpress</a>
      </li>
      <li>PHP</li>
      <li>MySQL</li>
      <li>APIs</li>
      <li>Mobile Apps</li>
      <li>HTML5 and CSS3 Speacial Features</li>
      <li>Python</li>
      <li>Twitter Clone using MVC</li>
    </ol>
    <p>
      A full description of version 2 of the course can be found at{" "}
      <a href="https://www.udemy.com/the-complete-web-developer-course-2/">
        www.udemy.com
      </a>{" "}
      after March 23rd 2016 or at{" "}
      <a href="https://www.udemy.com/complete-web-developer-course/">
        www.udemy.com
      </a>{" "}
      before.
    </p>
    <p>
      <Link to="/programming/courses">Back to Courses</Link>
    </p>
  </div>
);

export default CompWebDev;
